import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { Phase } from '../types/api'

type PhaseBannerProps = {
  displayFeedbackMessage?: boolean
  phase: Phase
}

const PhaseBanner: FC<PhaseBannerProps> = ({
  displayFeedbackMessage = false,
  phase
}) => {
  const capitalisedPhase = phase.toUpperCase()[0] + phase.toLowerCase().slice(1);

  return (
    <div className="phase-banner">
      <p className="phase-banner__content">
        <strong className="phase-banner__content__tag">
          {capitalisedPhase}
        </strong>
        <span className="phase-banner__text">
          {displayFeedbackMessage
            ? <span>This is a new service. Help us improve it and <Link className="govuk-link" to='/feedback/general' target='_blank'>give your feedback (opens in new tab)</Link>.</span>
            : <span>This is a new service.</span>
          }
        </span>
      </p>
    </div>
  )
}

export default PhaseBanner
